import React from "react";

const Navigation = () => (
  <section
    id="sidebar-navigation"
    className="flex flex-col items-center justify-between"
  >
    {/* <div className="text-xl font-medium text-secondary pt-2 border-b-2 border-highlight">
      Home
    </div>
    <div className="text-xl font-medium text-highlight pt-2">Experience</div>
    <div className="text-xl font-medium text-highlight pt-2">Portfolio</div>
    <div className="text-xl font-medium text-highlight pt-2">Blog</div>
    <div className="text-xl font-medium text-highlight pt-2">Contact</div> */}
  </section>
);

export default Navigation;
