import React from "react";
// components
import Details from "./Details";
import SocialMedia from "./SocialMedia";
import Navigation from "./Navigation";
import Sidebar from "../../components/Sidebar";

const Author = () => (
  <Sidebar>
    <div />
    <div />
    <div className="flex flex-col items-center justify-between">
      <Details />
      <SocialMedia />
    </div>
    <Navigation />
    <div />
  </Sidebar>
);

export default Author;
