import React from "react";
import isEmpty from "lodash/isEmpty";
import { Link } from "gatsby";
import Card from "../../../components/Card";
import PageTitle from "../../../components/PageTitle";
import TextContent from "../../../components/TextContent";

const ArticlesList = ({ articlesData }) => {
  const renderArticles = () => {
    if (articlesData && isEmpty(articlesData)) {
      return <div className="text-center">No article published yet.</div>;
    }

    return articlesData.map(
      ({
        id,
        slug,
        title,
        excerpt,
        published_at: publishedAt,
        original_published_at: originalPublishedAt,
      }) => (
        <div id={`article-${id}`} key={`article-${id}`} className="mb-5">
          <Link to={`/articles/${slug}`}>
            <Card>
              <div className="text-center mb-5">
                <PageTitle>{title}</PageTitle>
                <div className="text-secondary font-medium italic">
                  {originalPublishedAt || publishedAt}
                </div>
              </div>
              <div className="mt-4">
                <TextContent>{excerpt}</TextContent>
              </div>
            </Card>
          </Link>
        </div>
      )
    );
  };

  return (
    <section id="articles-page">
      <PageTitle>Articles</PageTitle>
      {renderArticles()}
    </section>
  );
};

export default ArticlesList;
