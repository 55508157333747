import React from "react";

const Details = () => (
  <section className="flex flex-col items-center justify-between">
    <img
      id="avatar-container"
      alt="avatar"
      className="rounded-full h-48 w-48 mb-2 bg-secondary"
      src="https://avatars.githubusercontent.com/jetrotullroa"
    />
    <div id="author-details" className="my-4">
      <div className="text-3xl font-semibold text-center text-highlight">
        Jetro Tull Roa
      </div>
      <div className="text-sm text-center font-medium text-highlight">
        Software Developer
      </div>
    </div>
  </section>
);

export default Details;
