import React from "react";

const Sidebar = ({ children }) => (
  <div className="h-screen col-span-5 md:col-span-1 bg-primary">
    <div className="h-screen p-1 flex flex-col flex-wrap justify-between items-center">
      {children}
    </div>
  </div>
);

export default Sidebar;
