import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faLinkedinIn,
  faGitlab,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";

const query = graphql`
  {
    allStrapiSocialMedias {
      nodes {
        name
        link
      }
    }
  }
`;

const SocialMedia = () => {
  const {
    allStrapiSocialMedias: { nodes: socialMediasData },
  } = useStaticQuery(query);

  const renderSocialMedias = () => {
    const activeSMIcons = [
      { name: "Twitter", icon: faTwitter },
      { name: "LinkedIn", icon: faLinkedinIn },
      { name: "Gitlab", icon: faGitlab },
      { name: "Github", icon: faGithub },
    ];
    return activeSMIcons.map((socialMedia) => {
      const socialMediaData = socialMediasData.find(
        (socialMediaSource) => socialMediaSource.name === socialMedia.name
      );
      return (
        socialMediaData && (
          <button
            type="button"
            onClick={() => window.open(socialMediaData?.link, "_blank")}
          >
            <FontAwesomeIcon
              id={`social-media-${socialMedia.name}`}
              className="text-highlight"
              icon={socialMedia.icon}
            />
          </button>
        )
      );
    });
  };

  return (
    <section
      id="social-media"
      className="flex flex-row justify-around items-stretch w-60"
    >
      {renderSocialMedias()}
    </section>
  );
};

export default SocialMedia;
