import React from "react";
import Author from "./Author";
import Content from "../components/Contents";

const Layout = ({ children }) => (
  <div className="h-screen max-h-full w-full bg-cover bg-center bg-gray-400 overflow-scroll">
    <div className="h-screen grid grid-cols-5">
      <Author />
      <div className="max-h-full col-span-5 md:col-span-4 bg-highlight overflow-scroll">
        <Content>{children}</Content>
      </div>
    </div>
  </div>
);

export default Layout;
